.malevil-packshot img {
  height: 500px;
}

.malevil-packshot {
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.malevil-01 {
  opacity: 0.45;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}

@media (max-width: 500px) {
  .malevil-01 {
    opacity: 100%;
  }
}

.malevil-01:hover {
  opacity: 100%;
}

.malevil-02 {
  opacity: 0.45;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}

@media (max-width: 500px) {
  .malevil-02 {
    opacity: 100%;
  }
}

.malevil-02:hover {
  opacity: 100%;
}

.malevil-03 {
  opacity: 0.45;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}

@media (max-width: 500px) {
  .malevil-03 {
    opacity: 100%;
  }
}

.malevil-03:hover {
  opacity: 100%;
}

.malevil-04 {
  opacity: 0.45;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}

@media (max-width: 500px) {
  .malevil-04 {
    opacity: 100%;
  }
}

.malevil-04:hover {
  opacity: 100%;
}

.malevil-05 {
  opacity: 0.45;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}

@media (max-width: 500px) {
  .malevil-05 {
    opacity: 100%;
  }
}

.malevil-05:hover {
  opacity: 100%;
}

.malevil-06 {
  opacity: 0.45;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}

@media (max-width: 500px) {
  .malevil-06 {
    opacity: 100%;
  }
}

.malevil-06:hover {
  opacity: 100%;
}

.malevil-text {
  margin: 100px 0 50px 0;
}

.malevil-text h1 {
  font-size: 30px;
  font-weight: 600;
}

.malevil-video {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
