.links-contact {
  margin: 220px 0 0 35px;
}

.links-contact a {
  font-family: scandia-web, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 80px;
  padding: 20px;
}

.links-contact .row {
  margin-top: 45px;
  border-bottom: solid white;
}

@media (max-width: 400px) {
  .links-contact a {
    font-size: 50px;
  }
}
