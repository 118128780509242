.mountains-packshot img {
  width: 100%;
  overflow: hidden;
}

.mountains-packshot {
  margin-top: 80px;
}

.pack {
  display: flex;
  flex-direction: row;
}

@media (max-width: 800px) {
  .pack {
    display: flex;
    flex-direction: column;
  }
}

.mountains-text {
  margin: 100px 0 100px 0;
}
