.project-content-memories {
  display: flex;
  align-items: center;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-shrink: 0;
  background-image: url(/src/img/sky-blue.png);
  width: 100%;
  height: 970px;
  // border-bottom: white solid 2px;
}

.project-content-memories h1 {
  font-size: 290px;
}

@media (max-width: 1437px) {
  .project-content-memories h1 {
    font-size: 230px;
  }
}

@media (max-width: 1222px) {
  .project-content-memories h1 {
    font-size: 200px;
  }
}

@media (max-width: 1065px) {
  .project-content-memories h1 {
    font-size: 150px;
  }
}

@media (max-width: 805px) {
  .project-content-memories h1 {
    font-size: 140px;
  }
}

@media (max-width: 749px) {
  .project-content-memories h1 {
    font-size: 120px;
  }
}

@media (max-width: 642px) {
  .project-content-memories h1 {
    font-size: 100px;
  }
}

@media (max-width: 538px) {
  .project-content-memories h1 {
    font-size: 90px;
  }
}

@media (max-width: 485px) {
  .project-content-memories h1 {
    font-size: 80px;
  }
}

@media (max-width: 440px) {
  .project-content-memories h1 {
    font-size: 70px;
  }
}

@media (max-width: 390px) {
  .project-content-memories h1 {
    font-size: 60px;
  }
}

.project-content-malevil {
  display: flex;
  align-items: center;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-shrink: 0;
  background-image: url(/src/img/clip-shot.png);
  width: 100%;
  height: 970px;
  border-bottom: white solid 2px;
}

.project-content-malevil h1 {
  font-size: 290px;
}

@media (max-width: 1437px) {
  .project-content-malevil h1 {
    font-size: 230px;
  }
}

@media (max-width: 1222px) {
  .project-content-malevil h1 {
    font-size: 200px;
  }
}

@media (max-width: 1065px) {
  .project-content-malevil h1 {
    font-size: 150px;
  }
}

@media (max-width: 805px) {
  .project-content-malevil h1 {
    font-size: 140px;
  }
}

@media (max-width: 749px) {
  .project-content-malevil h1 {
    font-size: 120px;
  }
}

@media (max-width: 642px) {
  .project-content-malevil h1 {
    font-size: 100px;
  }
}

@media (max-width: 538px) {
  .project-content-malevil h1 {
    font-size: 90px;
  }
}

@media (max-width: 485px) {
  .project-content-malevil h1 {
    font-size: 80px;
  }
}

@media (max-width: 440px) {
  .project-content-malevil h1 {
    font-size: 70px;
  }
}

@media (max-width: 390px) {
  .project-content-malevil h1 {
    font-size: 60px;
  }
}

.project-content-bigfoot {
  display: flex;
  align-items: center;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-shrink: 0;
  background-image: url(/src/img/bigfoot.png);
  width: 100%;
  height: 970px;
  // border-bottom: white solid 2px;
}

.project-content-bigfoot h1 {
  font-size: 290px;
}

@media (max-width: 1437px) {
  .project-content-bigfoot h1 {
    font-size: 230px;
  }
}

@media (max-width: 1245px) {
  .project-content-bigfoot h1 {
    font-size: 200px;
  }
}

@media (max-width: 1065px) {
  .project-content-bigfoot h1 {
    font-size: 150px;
  }
}

@media (max-width: 805px) {
  .project-content-bigfoot h1 {
    font-size: 140px;
  }
}

@media (max-width: 749px) {
  .project-content-bigfoot h1 {
    font-size: 120px;
  }
}

@media (max-width: 642px) {
  .project-content-bigfoot h1 {
    font-size: 100px;
  }
}

@media (max-width: 538px) {
  .project-content-bigfoot h1 {
    font-size: 90px;
  }
}

@media (max-width: 485px) {
  .project-content-bigfoot h1 {
    font-size: 80px;
  }
}

@media (max-width: 440px) {
  .project-content-bigfoot h1 {
    font-size: 70px;
  }
}

@media (max-width: 390px) {
  .project-content-bigfoot h1 {
    font-size: 60px;
  }
}

.project-content-mountains {
  display: flex;
  align-items: center;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-shrink: 0;
  background-image: url(/src/img/mountains.jpg);
  width: 100%;
  height: 970px;
  // border-bottom: white solid 2px;
}

.project-content-mountains h1 {
  font-size: 270px;
}

@media (max-width: 1437px) {
  .project-content-mountains h1 {
    font-size: 200px;
  }
}

@media (max-width: 1245px) {
  .project-content-mountains h1 {
    font-size: 150px;
  }
}

@media (max-width: 930px) {
  .project-content-mountains h1 {
    font-size: 140px;
  }
}

@media (max-width: 870px) {
  .project-content-mountains h1 {
    font-size: 120px;
  }
}

@media (max-width: 750px) {
  .project-content-mountains h1 {
    font-size: 110px;
  }
}

@media (max-width: 685px) {
  .project-content-mountains h1 {
    font-size: 90px;
  }
}

@media (max-width: 565px) {
  .project-content-mountains h1 {
    font-size: 80px;
  }
}

@media (max-width: 505px) {
  .project-content-mountains h1 {
    font-size: 70px;
  }
}

@media (max-width: 440px) {
  .project-content-mountains h1 {
    font-size: 60px;
  }
}

@media (max-width: 375px) {
  .project-content-mountains h1 {
    font-size: 58px;
  }
}

.project-content-pack {
  display: flex;
  align-items: center;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-shrink: 0;
  background-image: url(/src/img/pack-cover.jpg);
  width: 100%;
  height: 970px;
  // border-bottom: white solid 2px;
}

.project-content-pack h1 {
  font-size: 290px;
}

@media (max-width: 1437px) {
  .project-content-pack h1 {
    font-size: 230px;
  }
}

@media (max-width: 1245px) {
  .project-content-pack h1 {
    font-size: 200px;
  }
}

@media (max-width: 1065px) {
  .project-content-pack h1 {
    font-size: 150px;
  }
}

@media (max-width: 805px) {
  .project-content-pack h1 {
    font-size: 140px;
  }
}

@media (max-width: 749px) {
  .project-content-pack h1 {
    font-size: 120px;
  }
}

@media (max-width: 642px) {
  .project-content-pack h1 {
    font-size: 100px;
  }
}

@media (max-width: 538px) {
  .project-content-pack h1 {
    font-size: 90px;
  }
}

@media (max-width: 485px) {
  .project-content-pack h1 {
    font-size: 80px;
  }
}

@media (max-width: 440px) {
  .project-content-pack h1 {
    font-size: 70px;
  }
}

@media (max-width: 390px) {
  .project-content-pack h1 {
    font-size: 60px;
  }
}

.project-content-glitch {
  display: flex;
  align-items: center;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-shrink: 0;
  background-image: url(/src/img/glitch-title.png);
  width: 100%;
  height: 970px;
  // border-bottom: white solid 2px;
}

.project-content-glitch h1 {
  font-size: 290px;
}

@media (max-width: 1437px) {
  .project-content-glitch h1 {
    font-size: 230px;
  }
}

@media (max-width: 1245px) {
  .project-content-glitch h1 {
    font-size: 200px;
  }
}

@media (max-width: 1065px) {
  .project-content-glitch h1 {
    font-size: 150px;
  }
}

@media (max-width: 805px) {
  .project-content-glitch h1 {
    font-size: 140px;
  }
}

@media (max-width: 749px) {
  .project-content-glitch h1 {
    font-size: 120px;
  }
}

@media (max-width: 642px) {
  .project-content-glitch h1 {
    font-size: 100px;
  }
}

@media (max-width: 538px) {
  .project-content-glitch h1 {
    font-size: 90px;
  }
}

@media (max-width: 485px) {
  .project-content-glitch h1 {
    font-size: 80px;
  }
}

@media (max-width: 440px) {
  .project-content-glitch h1 {
    font-size: 70px;
  }
}

@media (max-width: 390px) {
  .project-content-glitch h1 {
    font-size: 60px;
  }
}

.project-content-vnl {
  display: flex;
  align-items: center;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-shrink: 0;
  background-image: url(/src/img/VNL-cover.jpg);
  width: 100%;
  height: 970px;
  // border-bottom: white solid 2px;
}

.project-content-vnl h1 {
  font-size: 290px;
}

@media (max-width: 1437px) {
  .project-content-vnl h1 {
    font-size: 230px;
  }
}

@media (max-width: 1245px) {
  .project-content-vnl h1 {
    font-size: 200px;
  }
}

@media (max-width: 1065px) {
  .project-content-vnl h1 {
    font-size: 150px;
  }
}

@media (max-width: 805px) {
  .project-content-vnl h1 {
    font-size: 140px;
  }
}

@media (max-width: 749px) {
  .project-content-vnl h1 {
    font-size: 120px;
  }
}

.project-content-manteca {
  display: flex;
  align-items: center;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-shrink: 0;
  background-image: url(/src/img/manteca.jpg);
  width: 100%;
  height: 970px;
  // border-bottom: white solid 2px;
}

.project-content-manteca h1 {
  font-size: 290px;
}

@media (max-width: 1437px) {
  .project-content-manteca h1 {
    font-size: 230px;
  }
}

@media (max-width: 1245px) {
  .project-content-manteca h1 {
    font-size: 200px;
  }
}

@media (max-width: 1065px) {
  .project-content-manteca h1 {
    font-size: 150px;
  }
}

@media (max-width: 805px) {
  .project-content-manteca h1 {
    font-size: 140px;
  }
}

@media (max-width: 749px) {
  .project-content-manteca h1 {
    font-size: 120px;
  }
}

@media (max-width: 642px) {
  .project-content-manteca h1 {
    font-size: 100px;
  }
}

@media (max-width: 538px) {
  .project-content-manteca h1 {
    font-size: 90px;
  }
}

@media (max-width: 485px) {
  .project-content-manteca h1 {
    font-size: 80px;
  }
}

@media (max-width: 440px) {
  .project-content-manteca h1 {
    font-size: 70px;
  }
}

@media (max-width: 390px) {
  .project-content-manteca h1 {
    font-size: 60px;
  }
}
