@media (max-width: 500px) {
  .profil-page {
    opacity: 100%;
  }
}

.profil-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-shrink: 0;
  background-image: url(/src/img/about-01.jpg);
  width: 100%;
  height: 950px;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}

.about p {
  font-size: 70px;
  font-weight: 400;
  margin-left: 10px;
}

.profil-page h1 {
  font-size: 140px;
}

@media (max-width: 590px) {
  .profil-page h1 {
    font-size: 80px;
  }
}

@media (max-width: 440px) {
  .profil-page h1 {
    font-size: 100px;
  }
}

@media (max-width: 360px) {
  .profil-page h1 {
    font-size: 95px;
  }
}

@media (max-width: 990px) {
  .about p {
    font-size: 40px;
  }
}

@media (max-width: 500px) {
  .about p {
    font-size: 20px;
  }
}

@media (max-width: 400px) {
  .about p {
    font-size: 17px;
  }
}

.about {
  margin: 95px 0 0 20px;
}

.about p {
  margin-top: 20px;
}

.logo-rd img {
  height: 190px;
  margin: 65px 0 0 25px;
}

// .photo-profil {
//   margin: 95px 0 0 35px;
//   gap: 30px;
// }

// .photo-profil img {
//   height: 490px;
//   // clip-path: circle(50% at 50% 50%);
//   opacity: 10%;
//   transition-duration: 0.5s;
//   transition-timing-function: ease-out;
// }

// .photo-profil img:hover {
//   opacity: 100%;
// }

.about-cv-button {
  margin: 65px 0 0 35px;
}

.about-pdf-button {
  display: inline-block;
  padding: 20px 30px;
  border: 2px solid white;
  background-color: transparent;
  color: black;
  text-decoration: none;
  text-align: center;
  transition: all 0.3s ease;
  background-color: white; /* Fond noir initial */
}

.about-pdf-button:hover {
  border-color: #da0000; /* Bordure rouge au survol */
  background-color: black; /* Fond noir au survol */
}
