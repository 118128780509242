/* ====================================
// css variables
// ==================================== */

// :root {
// }

/* ====================================
// reset rules
// ==================================== */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
  cursor: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  font-size: 1rem;
  line-height: 1.25;
  scroll-behavior: smooth;
  cursor: none;
}

a {
  color: inherit;
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease-out;
  color: inherit;
  text-decoration: none;
  cursor: none;
  font-family: scandia-web, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

a:hover {
  opacity: 0.5;
  color: #da0000;
}

@media (max-width: 500px) {
  a {
    font-size: 17px;
  }
}

@media (max-width: 375px) {
  a {
    font-size: 15px;
  }
}

p {
  font-family: scandia-web, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

@media (max-width: 500px) {
  p {
    font-size: 15px;
  }
}

h1 {
  color: #fff;
  font-family: scandia-web, sans-serif;
  font-weight: 400;
  font-style: normal;
  // font-size: 140px;
}

img,
video {
  max-inline-size: 100%;
  block-size: auto;
  vertical-align: middle;
}

/* ====================================
// base rules
// ==================================== */

body {
  background: black;
  color: white;
}

::selection {
  color: black;
  background: #da0000;
}

em {
  font-family: scandia-web, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 25px;
}

// strong {
//   font-weight: 400;
// }
