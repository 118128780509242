.projects-motion-box a {
  color: #fff;
  font-family: scandia-web, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 240px;
}

// .projects-box {
//   text-align: center;
// }

.projects-motion-1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-shrink: 0;
  background-image: url(/src/img/glitch-title.png);
  width: 100%;
  height: 403px;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  opacity: 0.45;
}

.projects-motion-1:hover {
  opacity: 100%;
}
