.container-footer {
  height: 409px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.container-footer p {
  opacity: 60%;
}
