nav {
  display: flex;
  justify-content: space-between;
  padding: 20px 50px;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  background: black;
}

nav p {
  font-weight: 700;
}

@media (max-width: 500px) {
  nav p {
    font-size: 17px;
  }
}

@media (max-width: 565px) {
  nav p {
    display: none;
  }
}
