.pack-packshot img {
  width: 100%;
  overflow: hidden;
}

.pack-packshot {
  margin-top: 80px;
}

.pack-tree {
  display: flex;
  flex-direction: row;
  // gap: 25px;
}

@media (max-width: 800px) {
  .pack-tree {
    display: flex;
    flex-direction: column;
  }
}

.pack-text {
  margin: 100px 0 100px 0;
}
