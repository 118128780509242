.projects-box a {
  color: #fff;
  font-family: scandia-web, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 180px;
}

@media (max-width: 1280px) {
  .projects-box a {
    font-size: 150px;
  }
}

@media (max-width: 990px) {
  .projects-box a {
    font-size: 130px;
  }
}

@media (max-width: 895px) {
  .projects-box a {
    font-size: 120px;
  }
}

@media (max-width: 767px) {
  .projects-box a {
    font-size: 95px;
  }
}

@media (max-width: 645px) {
  .projects-box a {
    font-size: 90px;
  }
}

@media (max-width: 560px) {
  .projects-box a {
    font-size: 80px;
  }
}

@media (max-width: 500px) {
  .projects-box a {
    font-size: 75px;
  }

  .projects-1 {
    opacity: 100%;
  }
}

@media (max-width: 470px) {
  .projects-box a {
    font-size: 70px;
  }
}

@media (max-width: 440px) {
  .projects-box a {
    font-size: 60px;
  }
}

@media (max-width: 375px) {
  .projects-box a {
    font-size: 50px;
  }
}

// .projects-box {
//   text-align: center;
// }

@media (max-width: 500px) {
  .projects-1 {
    opacity: 100%;
  }
}

.projects-1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-shrink: 0;
  background-image: url(/src/img/sky-blue.png);
  width: 100%;
  height: 403px;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  opacity: 0.45;
}

@media (max-width: 1024px) {
  .projects-1 {
    opacity: 10;
  }
}

.projects-1:hover {
  opacity: 100%;
}

.projects-1 p {
  font-size: 50px;
  font-weight: 200;
  margin-left: 10px;
}

@media (max-width: 500px) {
  .projects-1 p {
    font-size: 40px;
  }
}

@media (max-width: 400px) {
  .projects-1 p {
    font-size: 30px;
  }
}

.projects-2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-shrink: 0;
  background-image: url(/src/img/glitch-title.png);
  width: 100%;
  height: 403px;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  opacity: 0.45;
}

@media (max-width: 1024px) {
  .projects-2 {
    opacity: 10;
  }
}

.projects-2:hover {
  opacity: 100%;
}

.projects-2 p {
  font-size: 50px;
  font-weight: 200;
  margin-left: 10px;
}

@media (max-width: 500px) {
  .projects-2 p {
    font-size: 40px;
  }
}

@media (max-width: 400px) {
  .projects-2 p {
    font-size: 30px;
  }
}

.projects-3 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-shrink: 0;
  background-image: url(/src/img/clip-shot.png);
  width: 100%;
  height: 403px;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  opacity: 0.45;
}

@media (max-width: 1024px) {
  .projects-3 {
    opacity: 10;
  }
}

.projects-3:hover {
  opacity: 100%;
}

.projects-3 p {
  font-size: 50px;
  font-weight: 200;
  margin-left: 10px;
}

@media (max-width: 500px) {
  .projects-3 p {
    font-size: 40px;
  }
}

@media (max-width: 400px) {
  .projects-3 p {
    font-size: 30px;
  }
}
.projects-4 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-shrink: 0;
  background-image: url(/src/img/bigfoot.png);
  width: 100%;
  height: 403px;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  opacity: 0.45;
}

@media (max-width: 1024px) {
  .projects-4 {
    opacity: 10;
  }
}

.projects-4:hover {
  opacity: 100%;
}

.projects-4 p {
  font-size: 50px;
  font-weight: 200;
  margin-left: 10px;
}

@media (max-width: 500px) {
  .projects-4 p {
    font-size: 40px;
  }
}

@media (max-width: 400px) {
  .projects-4 p {
    font-size: 30px;
  }
}

.projects-5 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-shrink: 0;
  background-image: url(/src/img/mountains.jpg);
  width: 100%;
  height: 403px;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  opacity: 0.45;
}

@media (max-width: 1024px) {
  .projects-5 {
    opacity: 10;
  }
}

.projects-5:hover {
  opacity: 100%;
}

.projects-5 p {
  font-size: 50px;
  font-weight: 200;
  margin-left: 10px;
}

@media (max-width: 500px) {
  .projects-5 p {
    font-size: 40px;
  }
}

@media (max-width: 400px) {
  .projects-5 p {
    font-size: 30px;
  }
}

.projects-6 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-shrink: 0;
  background-image: url(/src/img/pack-cover.jpg);
  width: 100%;
  height: 403px;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  opacity: 0.45;
}

@media (max-width: 1024px) {
  .projects-6 {
    opacity: 10;
  }
}

.projects-6:hover {
  opacity: 100%;
}

.projects-6 p {
  font-size: 50px;
  font-weight: 200;
  margin-left: 10px;
}

@media (max-width: 500px) {
  .projects-6 p {
    font-size: 40px;
  }
}

@media (max-width: 400px) {
  .projects-6 p {
    font-size: 30px;
  }
}

.projects-7 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-shrink: 0;
  background-image: url(/src/img/VNL-cover.jpg);
  width: 100%;
  height: 403px;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  opacity: 0.45;
}

@media (max-width: 1024px) {
  .projects-7 {
    opacity: 10;
  }
}

.projects-7:hover {
  opacity: 100%;
}

.projects-7 p {
  font-size: 50px;
  font-weight: 200;
  margin-left: 10px;
}

@media (max-width: 500px) {
  .projects-7 p {
    font-size: 40px;
  }
}

@media (max-width: 400px) {
  .projects-7 p {
    font-size: 30px;
  }
}

.projects-8 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-shrink: 0;
  background-image: url(/src/img/manteca.jpg);
  width: 100%;
  height: 403px;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  opacity: 0.45;
  margin-top: 65px;
}

@media (max-width: 1024px) {
  .projects-8 {
    opacity: 10;
  }
}

.projects-8:hover {
  opacity: 100%;
}

.projects-8 p {
  font-size: 50px;
  font-weight: 200;
  margin-left: 10px;
}

@media (max-width: 500px) {
  .projects-8 p {
    font-size: 40px;
  }
}

@media (max-width: 400px) {
  .projects-8 p {
    font-size: 30px;
  }
}

.projects-content {
  display: flex;
  flex-direction: column;
}

.projects-content p {
  font-size: 260px;
}

.project-page {
  font-size: 30px;
  color: rgb(255, 255, 255);
  display: flex;
}
.projects-list {
  margin: 395px 0 0 40px;
}
